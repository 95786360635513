import { useQueryClient } from '@tanstack/vue-query'
import * as Sentry from '@sentry/vue'
import { tokenKey } from '~/constants/auth'
import useAuthStore from '~/store/auth'
import useFiltersStore from '~/store/filters'
import useUIStore from '~/store/ui'

const useSuccessfulLogout = () => {
  const { setIsAuth, clearRole } = useAuthStore()
  const { push } = useRouter()
  const queryClient = useQueryClient()
  const token = useStatefulCookie(tokenKey)
  const { clearFilters } = useFiltersStore()
  const { cleanPopup } = useUIStore()

  const logout = async () => {
    setIsAuth(false)
    clearRole()

    queryClient.clear()

    token.value = undefined

    // Sentry.io
    Sentry.setUser(null)

    await nextTick()

    clearFilters()

    cleanPopup()

    push({ name: 'login' })
  }

  return {
    logout,
  }
}

export default useSuccessfulLogout
