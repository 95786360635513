<template>
  <div>
    <div ref="sidebar" class="sidebar">
      <UILogo
        class="mx-auto"
        :to="{
          name: 'dashboard',
        }"
      />
      <div v-if="isMobile" class="sidebar__mobile-header">
        <p class="sidebar__mobile-title">Menu</p>
        <div class="sidebar__mobile-close" @click="emit('close-menu')">
          <UIIcon name="big-close" />
        </div>
      </div>
      <div id="sidebar-sections">
        <div class="sidebar__top-section mb-2">
          <UISidebarItem
            v-for="(item, index) in topItems"
            :id="`sidebar-item-${item.id}`"
            :key="item.text"
            :item="item"
            @click="handleClick(item, index)"
          />
        </div>
        <div class="sidebar__bottom-section">
          <FeaturesManagerCard v-if="(isGuest || isPartner) && user?.manager" :manager="user?.manager" />

          <UISidebarItem
            v-for="item in bottomItems"
            :id="`sidebar-item-${item.id}`"
            :key="item.text"
            :class="item.class"
            :item="item"
            @click="handleClick(item)"
          />
        </div>
      </div>
    </div>
    <div class="sidebar-background"></div>
  </div>
</template>

<script setup lang="ts">
import { version } from '@/package.json'
import useAuthStore from '~/store/auth'
import { PERMISSIONS } from '~/types/permissions'
import type { SidebarItem } from '~/types/ui'

const props = defineProps<{
  disableFetch?: boolean
}>()

const online = useOnline()

const { isGuest, isPartner, isPartnerManager, isCEO, isSuperAdmin, isAuth } = storeToRefs(useAuthStore())

const isFetchEnabled = computed(() => !props.disableFetch && online.value && isAuth.value)

const { data: user } = useCurrentUser(isFetchEnabled.value)

const { data: referrals } = useEntityCount(ENTITY_KEYS.REFERRALS, isFetchEnabled.value)

const { data: invoices } = useEntityCount(ENTITY_KEYS.INVOICES, isFetchEnabled.value)

const { data: commissions } = useEntityCount(ENTITY_KEYS.COMMISSIONS, isFetchEnabled.value)

const { check } = usePermissionCheck()

const emit = defineEmits<{
  (e: 'close-menu'): void
}>()

const { isMobile } = useAppBreakpoints()

const topItems = computed<SidebarItem[]>(() => [
  {
    id: 'dashboard',
    icon: 'dashboard',
    link: 'dashboard',
    text: 'Dashboard',
  },
  {
    id: 'referrals',
    icon: 'user-group',
    link: 'referrals',
    text: `Referrals ${
      (isPartnerManager.value || isCEO.value || isSuperAdmin.value) && user.value?.meta?.referrals
        ? `(${user.value?.meta?.referrals})`
        : ''
    }`,
    disabled: (isGuest.value || isPartner.value) && !referrals.value && !isMobile,
  },
  ...(check([PERMISSIONS.CAN_MANAGE_PARTNERS])
    ? [
        {
          id: 'partners',
          icon: 'handshake',
          link: 'partners',
          text: `Partners ${user.value?.meta?.partners ? `(${user.value?.meta?.partners})` : ''}`,
        },
      ]
    : []),

  ...(check([PERMISSIONS.VIEW_PROPERTY_CATALOGUE]) && !isMobile
    ? [
        {
          id: 'properties',
          icon: 'home',
          link: 'properties',
          text: 'Property Catalogue',
          active: route.name === 'properties-id',
        },
      ]
    : []),
  ...(check([PERMISSIONS.CAN_VIEW_DEAL_COMMISSIONS]) && !isMobile
    ? [
        {
          id: 'finances',
          icon: 'file-text',
          link: 'finances',
          text: `Finances ${commissions.value ? `(${commissions.value})` : ''}`,
          active: route.name === 'commissions',
        },
      ]
    : []),
  {
    id: 'earnings',
    icon: 'money',
    link: 'earnings',
    text: 'Earnings',
    disabled: (isGuest.value || isPartner.value) && !referrals.value && !isMobile,
  },
  ...(!isMobile
    ? [
        {
          id: 'invoices',
          icon: 'file',
          link: 'invoices',
          text: `Invoices ${invoices.value ? `(${invoices.value})` : ''}`,
        },
      ]
    : []),
  ...(check([PERMISSIONS.ACCESS_TO_MANAGEMENT_ADMIN_SECTION])
    ? [
        {
          id: 'management',
          icon: 'settings',
          link: 'management',
          text: 'Management',
          active: route.name === 'users-id',
        },
      ]
    : []),
])

const { mutateAsync: logout, isPending } = useLogout()

const bottomItems = computed<SidebarItem[]>(() => [
  ...(check([PERMISSIONS.EDIT_PERSONAL_USER_PROFILE_AS_NEEDED])
    ? [
        {
          id: 'profile',
          icon: 'user',
          link: 'profile',
          text: 'User Profile',
        },
      ]
    : []),
  {
    id: 'logout',
    icon: 'log-out',
    class: 'sidebar-item--logout',
    text: 'Log out',
    disabled: isPending.value || !isAuth.value,
    action: () => logout(),
  },
  {
    id: 'version',
    text: `Client v${version}`,
    class: 'sidebar-item--version',
    disabled: true,
  },
])

const currentIndex = ref(0)

const route = useRoute()

watch(
  () => route.name,
  () => {
    switch (route.name) {
      case 'dashboard':
        currentIndex.value = 0
        break
      case 'referrals':
        currentIndex.value = 1
        break
      case 'earnings':
        currentIndex.value = 2
        break
    }
  },
  { immediate: true },
)

const handleClick = (item: SidebarItem, index?: number) => {
  if (index) {
    currentIndex.value = index
  }

  if (isMobile) {
    emit('close-menu')
  }

  if (item.action) {
    item.action()

    return
  }

  // Workaround in case user on [id] page and want to navigate to the table
  if (item.link) {
    const isOnTheSamePage = item.link === route.name

    if (isOnTheSamePage) {
      return
    }

    navigateTo({ name: item.link })
  }
}

const sidebar = ref<HTMLDivElement | null>(null)

onClickOutside(sidebar, () => {
  if (!isMobile) return

  emit('close-menu')
})
</script>

<style scoped lang="scss">
@import '@/styles/variables.scss';
.sidebar {
  display: flex;
  flex-direction: column;
  padding: 32px 24px;
  width: $sidebar-width;
  height: 100dvh;
  background-color: $bg;
  position: fixed;

  @media screen and (max-width: 1023px) {
    transform: translateX(-100%);
    z-index: 9999;
    border-radius: 0px 16px 16px 0px;
    width: 227px;
    padding: 0;
    overflow: hidden;
    transition: 0.2s ease-in-out;
  }

  @media screen and (min-width: 1920px) {
    width: $sidebar-width-big;
  }

  &-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(7px);
    opacity: 0;
    z-index: -1;
  }

  .logo {
    display: block;
    margin-bottom: 32px;
    text-align: center;
    color: $black-100;
    @media screen and (max-width: 1023px) {
      display: none;
    }
  }

  .manager-card {
    margin-top: auto;
    margin-bottom: 16px;

    @media screen and (max-width: 1023px) {
      display: none;
    }
  }

  #sidebar-sections {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    @media screen and (max-width: 1023px) {
      background-color: $bg;
      padding: 24px 40px 24px 4px;
    }
  }

  &__top-section,
  &__bottom-section {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  &__bottom-section {
    margin-top: auto;
  }

  &-item {
    &-wrapper {
      &.sidebar-item--version {
        :deep() {
          .sidebar-item {
            border-top: $base-border-width solid $black-10;
            border-radius: 0;
            padding-top: 16px;
            color: $black-60;
            margin-top: 8px;
            font-size: 14px;
            padding-right: 0;
          }
        }
      }
    }
  }
  &__mobile {
    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: white;
      padding: 16px;
    }
    &-title {
      font-weight: 500;
    }
  }
}
.sidebar--open {
  .sidebar {
    transform: translateX(0);
    &-background {
      opacity: 1;
      z-index: 999;
    }
  }
}
</style>
