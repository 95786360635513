import { useMutation } from '@tanstack/vue-query'
import type { SuccessResponse } from '~/types/response'

const useLogout = () => {
  const { logout } = useSuccessfulLogout()
  const { fetch } = useAPIFetch()

  return useMutation({
    mutationFn: () =>
      fetch<SuccessResponse<{}>>({
        method: 'POST',
        url: 'users/logout',
      }),
    onSettled: async () => {
      await logout()
    },
  })
}

export default useLogout
